@import '../../styles/sizes';
@import '../../styles/colors';

.loader {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);

  svg {
    width: $size-36;
    height: $size-36;
    fill: $primary-color
  }
}