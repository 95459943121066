@import "../../styles/sizes";

.admin {
  .title {
    margin-bottom: $size-24;

    .ant-btn {
      margin-right: $size-12;
    }

    h1 {
      margin-bottom: 0;
    }
  }

  form {
    width: 600px;
  }

  .btn-container {
    .ant-btn {
      margin-right: $size-12;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
