// colors
$black-color: #1b1b1b;
$white-color: #fff;
$bg-color: #e5e5e5;
$primary-color: #1677ff;
$dark-primary-color: #001529;
$success-color: #52c41a;
$success-bg-color: #f6ffed;
$warning-color: #faad14;
$warning-bg-color: #fffbe6;
$danger-color: #ff4d4f;
$danger-bg-color: #fff2f0;
