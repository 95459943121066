@import "../../styles/sizes";

.user {
  .title {
    align-items: center;
    margin-bottom: $size-24;

    & > div {
      width: $size-48;
      height: $size-48;
      margin-right: $size-12;

      .preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    .ant-btn {
      margin-right: $size-12;
    }

    h1 {
      margin-bottom: 0;
    }
  }

  h2 {
    margin-top: $size-24;
  }

  .photo {
    width: 100%;
    max-width: 300px;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .ant-list {
    width: 100%;
  }
}
