$size-1: 0.0625rem;
$size-2: 0.125rem;
$size-3: 0.1875rem;
$size-4: 0.25rem;
$size-8: 0.5rem;
$size-12: 0.75rem;
$size-16: 1rem;
$size-20: 1.25rem;
$size-24: 1.5rem;
$size-28: 1.75rem;
$size-32: 2rem;
$size-36: 2.25rem;
$size-40: 2.5rem;
$size-44: 2.75rem;
$size-48: 3rem;
$size-60: 3.75rem;
$size-80: 5rem;
