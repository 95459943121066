@font-face {
  font-family: 'HK Grotesk';
  src: url("../assets/fonts/HKGrotesk-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url("../assets/fonts/HKGrotesk-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url("../assets/fonts/HKGrotesk-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url("../assets/fonts/HKGrotesk-SemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url("../assets/fonts/HKGrotesk-Bold.otf") format("opentype");
  font-weight: 700;
}

html {
  font-size: 16px;
}

body {
  font-family: 'HK Grotesk', Arial, Helvetica, sans-serif;
}

.text-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}