@import "../../styles/sizes";

.admins {
  .ant-table-wrapper {
    width: 100%;

    tr {
      cursor: pointer;
    }
  }

  .btn-container {
    margin-bottom: $size-12;
  }
}
