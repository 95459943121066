@import './typography';
@import './colors';
@import './sizes';

body {
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  background-color: $bg-color;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

main {
  padding: $size-12;
}

.ant-layout {
  .ant-layout-sider {
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $size-12 $size-12;
    }

    .favicon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $size-2;
      background: $bg-color;
      border-radius: $size-8;

      svg {
        width: $size-28;
        height: $size-28;
      }
    }
  }
}

.ant-menu {
  background: transparent;
}

.ant-layout-header {
  padding: 0 12px;
}