@import "../../styles/sizes";

.hypelist {
  &__topbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: $size-24;

    h1 {
      margin-bottom: 0;
      margin-left: $size-12;
      margin-right: auto;
    }
  }

  h3 {
    margin-top: $size-24;
  }

  &__photo {
    width: 100%;
    height: 100%;
    max-height: 250px;

    & > div {
      width: 100%;
      height: 100%;
      max-height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .ant-list {
    width: 100%;

    .ant-list-item-meta-avatar {
      & > div {
        width: $size-80;
        height: $size-80;

        .preview {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }
    }
  }
}
