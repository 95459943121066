@import "../../styles/sizes";

.reported {
  .ant-table-wrapper {
    width: 100%;
    margin-top: $size-12;

    tbody tr {
      cursor: pointer;
    }
  }

  .ant-space {
    width: 100%;
  }

  .ant-space-item {
    flex-grow: 1;

    &:first-of-type {
      width: 100%;
    }
  }
}
